import { STATIC_ASSET_BASE_URL } from '@/utility/constants';

export const sideImageData = {
  alt: 'side image login',
  mobileImgProps: {
    src: `${STATIC_ASSET_BASE_URL}/nasIO/loginPage/login-manager-banner.png`,
    width: '100%',
    height: '100%'
  },
  desktopImgProps: {
    src: `${STATIC_ASSET_BASE_URL}/nasIO/loginPage/login-manager-banner.png`,
    layout: 'fill'
  }
};

export const NO_COMMUNITY_ERROR =
  'Learner does not have any community subscriptions';
