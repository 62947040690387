import { t } from '@/utility/localization';
import FloatingLabelInput from '@/components/common/Form/FloatingLabelInput';
import { useEffect, useRef, useState } from 'react';

const OtpInTextField = ({
  maxLength = 6,
  defaultCountdown = 30,
  focus,
  label,
  placeholder,
  name,
  onResend,
  onChangeValue,
  error
}) => {
  const [otpCode, setOtpCode] = useState('');
  const [countDown, setCountDown] = useState(defaultCountdown);
  const intervalRef = useRef();

  useEffect(() => {
    setCountDown(defaultCountdown);
  }, [defaultCountdown]);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setCountDown((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000); //per second

    return () => {
      clearInterval(intervalRef.current);
    };
    //only call this once when page loads
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (otpCode) {
      onChangeValue?.(otpCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpCode]);

  const handleOnChangeOtp = (e) => {
    let value = e.target.value;

    if (value.length >= maxLength) {
      value = value.substr(0, maxLength);
    }

    setOtpCode(value);

    if (value === '') {
      onChangeValue?.(value);
    }
  };

  return (
    <FloatingLabelInput
      type="number"
      name={name}
      placeholder={placeholder}
      label={label}
      focus={focus}
      value={otpCode}
      onChange={handleOnChangeOtp}
      error={error}
      sideLabel={() => (
        <button
          type="button"
          className="text-button-md text-npl-text-icon-on-light-surface-secondary"
          disabled={countDown}
          onClick={() => {
            onResend?.();
            setCountDown(defaultCountdown);
          }}>
          {countDown
            ? t('resend-in-count-seconds', { count: countDown })
            : `${t('resend')}`}
        </button>
      )}
    />
  );
};

export default OtpInTextField;
