import { CHECKOUT_ORIGIN_TYPE } from '@/features/CommunitySignupModal/constants';
import {
  SIGNUP_ORIGIN,
  SIGNUP_REQUESTOR,
  WHATSAPP_SIGNUP_REQUESTOR
} from '@/features/SignUpPopup/constants';
import { DISCORD_UUID_KEY } from '@/features/community/constants';
import { getTrackingInformationForBE } from '@/utility/analytics';
import { EVENT_ACTION_TYPE, POST_ACTION_TYPE } from '@/utility/constants';
import { getTimezoneId } from '@/utility/dateHelper';
import sessionStorageService from '@/utility/sessionStorageService';

// using this function to get the discounted pricing for a learner if they have applied any discount coupon
export const getPricingForALearner = (learnerItem) => {
  if (learnerItem?.communityPurchaseTransaction?.discountAvailed) {
    return learnerItem?.communityPurchaseTransaction?.amount / 100;
  }
  return Math.round(learnerItem?.amount / 100);
};

export const getCalculatedDiscountPercentage = ({
  originalPrice,
  discountedPrice
}) => {
  try {
    return Math.round(
      ((originalPrice - discountedPrice) / originalPrice) * 100
    );
  } catch (e) {
    console.error(e);
  }
};

export const getSourceEntityInfo = ({
  signUpOrigin,
  entityId,
  entityMetadata = null
}) => {
  switch (signUpOrigin) {
    case SIGNUP_ORIGIN.EVENT_PAGE:
      return {
        type: CHECKOUT_ORIGIN_TYPE.EVENT,
        origin: entityId,
        metadata: {
          ticketCount: entityMetadata?.ticketCount || 1
        }
      };
    case SIGNUP_ORIGIN.RESOURCE_PAGE:
      return {
        type: CHECKOUT_ORIGIN_TYPE.FOLDER,
        origin: entityId
      };
    case SIGNUP_ORIGIN.CHALLENGE_PAGE:
      return {
        type: CHECKOUT_ORIGIN_TYPE.CHALLENGE,
        origin: entityId
      };
    case SIGNUP_ORIGIN.POST_PAGE:
      return { type: CHECKOUT_ORIGIN_TYPE.POST, origin: entityId };
    case SIGNUP_ORIGIN.ONE_ON_ONE_SESSION_PAGE:
      return {
        type: CHECKOUT_ORIGIN_TYPE.ONE_ON_ONE_SESSION,
        origin: entityId,
        metadata: {
          sessionStartTime: entityMetadata?.sessionStartTime,
          sessionEndTime: entityMetadata?.sessionEndTime,
          sessionTz: entityMetadata?.sessionTz
        }
      };
    default:
      return null;
  }
};

export const getSourceInfo = ({
  signUpOrigin,
  eventInfo,
  resourceInfo,
  challengeInfo,
  postInfo,
  entityMetadata
}) => {
  switch (signUpOrigin) {
    case SIGNUP_ORIGIN.EVENT_PAGE:
      return {
        type: CHECKOUT_ORIGIN_TYPE.EVENT,
        origin: eventInfo?._id,
        metadata: {
          ticketCount: entityMetadata?.ticketCount || 1
        }
      };
    case SIGNUP_ORIGIN.RESOURCE_PAGE:
      return {
        type: CHECKOUT_ORIGIN_TYPE.FOLDER,
        origin: resourceInfo?._id
      };
    case SIGNUP_ORIGIN.CHALLENGE_PAGE:
      return {
        type: CHECKOUT_ORIGIN_TYPE.CHALLENGE,
        origin: challengeInfo?._id
      };
    case SIGNUP_ORIGIN.POST_PAGE:
      return { type: CHECKOUT_ORIGIN_TYPE.POST, origin: postInfo?._id };
    case SIGNUP_ORIGIN.ONE_ON_ONE_SESSION_PAGE:
      return {
        type: CHECKOUT_ORIGIN_TYPE.ONE_ON_ONE_SESSION,
        origin: resourceInfo?._id,
        metadata: {
          sessionStartTime: entityMetadata?.sessionStartTime,
          sessionEndTime: entityMetadata?.sessionEndTime,
          sessionTz: entityMetadata?.sessionTz
        }
      };
    default:
      return null;
  }
};

export const getCommunitySignupPayload = ({
  communityInfo,
  phoneNumber,
  email,
  fullName,
  selectedPricingOption,
  currentLocale,
  sourceInfo,
  fromWidget
}) => {
  const { code } = communityInfo;
  //if no users are using the phone number and email, user can enroll this community
  let payload = {
    timezone: getTimezoneId(),
    communityCode: code,
    phoneNumber,
    email,
    captchaToken: null,
    isDirectSignUpEmail: false,
    interval: selectedPricingOption?.recurring?.interval ?? 'month',
    intervalCount: selectedPricingOption?.recurring?.interval_count ?? 1,
    priceId: selectedPricingOption?.id,
    trackingData: getTrackingInformationForBE(),
    requestor: communityInfo?.isWhatsappExperienceCommunity
      ? WHATSAPP_SIGNUP_REQUESTOR
      : SIGNUP_REQUESTOR,
    languagePreference: currentLocale
  };

  if (fullName) payload.fullName = fullName;
  if (sourceInfo) payload.source = sourceInfo;
  if (fromWidget) payload.fromWidget = 1;

  return payload;
};

export const getCheckoutActionType = ({ signUpOrigin }) => {
  switch (signUpOrigin) {
    case SIGNUP_ORIGIN.EVENT_PAGE:
      return EVENT_ACTION_TYPE;
    case SIGNUP_ORIGIN.POST_PAGE:
      return POST_ACTION_TYPE;
    default:
      return null;
  }
};

export const getCheckoutRequestor = ({ communityInfo }) => {
  return communityInfo?.isWhatsappExperienceCommunity
    ? WHATSAPP_SIGNUP_REQUESTOR
    : SIGNUP_REQUESTOR;
};

export const getUnifiedSignupMemberInfo = (userData) => {
  return {
    email: userData?.email?.toLowerCase() || '',
    firstName: userData?.learner?.firstName || '',
    lastName: userData?.learner?.lastName || '',
    languagePreference:
      userData?.learner?.languagePreference ||
      window.localizationData?.locale ||
      'en',
    uuid: sessionStorageService.getItem(DISCORD_UUID_KEY)
  };
};

export const getUnifiedSignupPaymentProvider = (communityInfo) => {
  return communityInfo?.payment_methods?.[0]?.value || 'stripe';
};
