import { useFirebaseContext } from '@/contexts/FirebaseContext';
import { usePixelCtx } from '@/contexts/PixelsContext';
import Auth from '@/modules/Auth';
import config from '@/utility/config';
import { TRACK_TWITTER_CLICK_ID_KEY } from '@/utility/constants';
import sessionStorageService from '@/utility/sessionStorageService';
import { useEffect } from 'react';

export const FIRESTORE_COLLECTIONS = {
  PIXEL_EVENT_LOGS: 'pixel_event_logs'
};

const PIXEL_PLATFORMS = {
  FACEBOOK: 'facebook',
  TIKTOK: 'tiktok',
  GA4: 'ga4',
  TWITTER: 'twitter'
};

const INITIATE_CHECKOUT_EVENT_NAME = 'InitiateCheckout';

const getUserDataPayloadForFbPixel = () => {
  const userId = Auth.getUserIdFromSession();
  const userDataPayload = userId ? { user_data: { user_id: userId } } : {};
  return userDataPayload;
};

const getUserDataPayloadForTwitterPixel = () => {
  const email = Auth.getUserEmailFromSession();
  return email ? { email_address: email } : {};
};

const formatCheckoutItemsForFbPixel = (checkoutItems) => {
  if (!Array.isArray(checkoutItems)) {
    return [];
  }
  return checkoutItems.map((item) => {
    return {
      id: item.id,
      name: item.name,
      quantity: 1
    };
  });
};

const formatCheckoutItemsForGA = (checkoutItems) => {
  if (!Array.isArray(checkoutItems)) {
    return [];
  }
  return checkoutItems.map((item) => {
    return {
      item_id: item.id,
      item_name: item.name,
      quantity: 1,
      price: item.price,
      currency: item.currency
    };
  });
};

const formatCheckoutItemsForTwitter = (checkoutItems) => {
  if (!Array.isArray(checkoutItems)) {
    return [];
  }
  return checkoutItems.map((item) => {
    return {
      content_id: item.id,
      content_name: item.name,
      content_price: item.price,
      num_items: 1
    };
  });
};

/**
 * Custom hook for pixel tracking.
 *
 * @param {Object} options - The options for pixel tracking.
 * @param {Object} options.communityId - The community ID.
 * @param {Object} options.trackingPixels - The tracking pixels for Facebook and TikTok.
 * @param {Object} options.trackingPixels.facebookPixel - The Facebook tracking pixel.
 * @param {string} options.trackingPixels.tiktokPixel - The TikTok tracking pixel.
 * @param {string} options.trackingPixels.gaMeasurementId - The Google Analytics measurement ID.
 * @param {Object} options.postInitProcess - The post initialization process for tracking.
 * @param {boolean} options.postInitProcess.trackPageView - Whether to track page view after initialization.
 * @param {boolean} options.postInitProcess.trackInitCheckout - Whether to track init checkout after initialization.
 * @returns {Object} - An object containing functions for tracking events on Facebook and TikTok pixels.
 */
const usePixelTracking = ({
  communityId,
  trackingPixels,
  postInitProcess
}) => {
  const { facebookPixel, tikTokPixel, gaMeasurementId, twitterPixelId } =
    trackingPixels || {};

  const { initialized: firebaseInitialized, firestoreUtils } =
    useFirebaseContext();

  const {
    // fb
    fbTrackingPixel,
    initFbTrackingPixel,
    clearFbTrackingPixel,
    // tiktok
    tiktokTrackingPixel,
    initTiktokTrackingPixel,
    clearTiktokTrackingPixel,
    // ga4
    gaPixel,
    initGoogleAnalytics,
    clearGaPixel,
    // twitter (X)
    twitterPixel,
    initTwitterPixel,
    clearTwitterPixel
  } = usePixelCtx();

  const logPixelEventToFirestore = async ({
    event = '',
    pixelId = '',
    platform = '',
    payload = {}
  }) => {
    // Check if community whitelisted for saving logs to firestore.
    if (!config?.whitelistedCommIdForPixelLogging.includes(communityId)) {
      return;
    }

    const firestorePayload = {
      community_id: communityId,
      event_name: event,
      pixel_id: pixelId,
      pixel_platform: platform,
      timestamp: Date.now(),
      page_path: window.location.pathname,
      email: Auth.getUserEmailFromSession() || '',
      payload
    };

    const doc = await firestoreUtils?.addDoc(
      FIRESTORE_COLLECTIONS.PIXEL_EVENT_LOGS,
      firestorePayload
    );

    return doc;
  };

  const logPageViewEventToFirestore = ({ pixelId, platform }) => {
    logPixelEventToFirestore({
      event: 'PageView',
      pixelId,
      platform
    });
  };

  const trackEventOnFbPixel = (event, payload = {}) => {
    if (fbTrackingPixel) {
      const userDataPayload = getUserDataPayloadForFbPixel();
      fbTrackingPixel?.pixel?.track(event, {
        ...payload,
        ...userDataPayload
      });
    }

    logPixelEventToFirestore({
      event,
      pixelId: facebookPixel,
      platform: PIXEL_PLATFORMS.FACEBOOK,
      payload
    });
  };

  const trackEventOnTiktokPixel = (event, payload) => {
    if (tiktokTrackingPixel) {
      tiktokTrackingPixel?.pixel?.track(event, payload);

      logPixelEventToFirestore({
        event,
        pixelId: tikTokPixel,
        platform: PIXEL_PLATFORMS.TIKTOK,
        payload
      });
    }
  };

  const trackEventOnGaPixel = (event, payload = {}) => {
    if (gaMeasurementId) {
      const userId = Auth.getUserIdFromSession();
      gaPixel?.pixel?.event(event, { ...payload, user_id: userId });

      logPixelEventToFirestore({
        event,
        pixelId: gaMeasurementId,
        platform: PIXEL_PLATFORMS.GA4,
        payload
      });
    }
  };

  const trackEventOnTwitterPixel = (eventName, payload = {}) => {
    if (twitterPixel) {
      twitterPixel?.pixel('event', eventName, payload);

      logPixelEventToFirestore({
        event,
        pixelId: twitterPixelId,
        platform: PIXEL_PLATFORMS.TWITTER,
        payload
      });
    }
  };

  /** Track Purchase Event Functions */
  const trackPurchaseEventOnTwitterPixel = (
    eventName = 'Purchase',
    purchasePayload = {}
  ) => {
    if (twitterPixel) {
      // checkout items
      const formattedCheckoutItems = formatCheckoutItemsForTwitter(
        purchasePayload?.checkoutItems
      );

      // user data
      const userDataPayload = getUserDataPayloadForTwitterPixel();

      // twclid
      const twclid = sessionStorageService?.getItem(
        TRACK_TWITTER_CLICK_ID_KEY
      );
      const twclidPayload = twclid ? { twclid } : {};

      let trackingPayload = {
        value: purchasePayload?.value,
        currency: purchasePayload?.currency,
        conversion_id: purchasePayload?.transaction_id,
        status: 'completed',
        contents: formattedCheckoutItems,
        ...userDataPayload,
        ...twclidPayload
      };

      trackEventOnTwitterPixel(eventName, trackingPayload);
    }
  };

  /**
   * Tracks a subscribe event on the all pixels. Used when membership checkout is successful
   *
   * @param {Object} payload - The payload for the purchase event.
   * @param {number} payload.value - The value of the purchase.
   * @param {string} payload.currency - The currency of the purchase.
   * @param {Object[]} payload.checkoutItems - An array with the id and name of the items being purchased. This will be community id and communtiy name
   * @param {string} payload.checkoutItems[].id - The ID of community
   * @param {string} payload.checkoutItems[].name - The title of community
   * @param {string} payload.transaction_id - The signupId of the transaction.
   * Note, you can send more parameters in payload in addition to value and currency as well. But value and currency are mandatory.
   */
  const trackSubscribeEventOnPixels = (payload) => {
    const PURCHASE_SUB_EVENT_NAME = 'nasio_purchase_subscription';

    // validate payload if it has value and currency
    if (
      !Object.prototype.hasOwnProperty.call(payload, 'value') ||
      !Object.prototype.hasOwnProperty.call(payload, 'currency')
    ) {
      console.warn(
        "Payload for purchase event doesn't have value or currency"
      );
      return;
    }

    const userId = Auth.getUserIdFromSession();

    if (fbTrackingPixel) {
      const formattedCheckoutItems = formatCheckoutItemsForFbPixel(
        payload?.checkoutItems
      );
      const contentIds = formattedCheckoutItems.map((item) => item.id);
      const userDataPayload = getUserDataPayloadForFbPixel();

      const fbSubscribeEventPayload = {
        ...payload,
        ...userDataPayload,
        contents: formattedCheckoutItems,
        content_ids: contentIds,
        content_name: payload?.checkoutItems?.[0]?.name // can do this because there is always only one item in checkout (this is only for one time checkout).
      };
      trackEventOnFbPixel('Subscribe', fbSubscribeEventPayload);
      trackEventOnFbPixel(
        PURCHASE_SUB_EVENT_NAME,
        fbSubscribeEventPayload
      );
    }

    if (tiktokTrackingPixel) {
      trackEventOnTiktokPixel('Subscribe', payload);
      trackEventOnTiktokPixel(PURCHASE_SUB_EVENT_NAME, payload);
    }

    if (gaPixel) {
      const gaSubscribeEventPayload = {
        ...payload,
        user_id: userId,
        items: formatCheckoutItemsForGA(payload?.checkoutItems)
      };
      trackEventOnGaPixel('purchase', gaSubscribeEventPayload);
      trackEventOnGaPixel(
        PURCHASE_SUB_EVENT_NAME,
        gaSubscribeEventPayload
      );
    }

    if (twitterPixel) {
      trackPurchaseEventOnTwitterPixel('Purchase', payload);
      trackPurchaseEventOnTwitterPixel(PURCHASE_SUB_EVENT_NAME, payload);
    }
  };

  /**
   * Tracks a purchase event on the all pixels. Used when one-time payment is successful
   *
   * @param {Object} payload - The payload for the purchase event.
   * @param {number} payload.value - The value of the purchase.
   * @param {string} payload.currency - The currency of the purchase.
   * @param {Object[]} payload.checkoutItems - An array with the id and name of the items being purchased. Usually a single item with event/folder/entity.
   * @param {string} payload.checkoutItems[].id - The ID of the checkout item.
   * @param {string} payload.checkoutItems[].name - The name of the checkout item.
   * @param {'event'|'folder'|'session'|'challenge'}} payload.type - The entity type of the checkout item.
   * @param {string} payload.transaction_id - The signupId of the transaction.
   * Note, you can send more parameters in payload in addition to value and currency as well. But value and currency are mandatory.
   */
  const trackPurchaseEventOnPixels = (payload) => {
    // validate payload if it has value and currency
    if (
      !Object.prototype.hasOwnProperty.call(payload, 'value') ||
      !Object.prototype.hasOwnProperty.call(payload, 'currency')
    ) {
      console.warn(
        "Payload for purchase event doesn't have value or currency"
      );
      return;
    }

    const ENTITY_PURCHSE_EVENT_NAME = `nasio_purchase_${payload.type}`;
    const userId = Auth.getUserIdFromSession();

    if (fbTrackingPixel) {
      const formattedCheckoutItems = formatCheckoutItemsForFbPixel(
        payload?.checkoutItems
      );
      const contentIds = formattedCheckoutItems.map((item) => item.id);
      const userDataPayload = getUserDataPayloadForFbPixel();

      const fbPurchaseEventPayload = {
        ...payload,
        ...userDataPayload,
        contents: formattedCheckoutItems,
        content_ids: contentIds,
        content_name: payload?.checkoutItems?.[0]?.name // can do this because there is always only one item in checkout (this is only for one time checkout).
      };

      trackEventOnFbPixel('Purchase', fbPurchaseEventPayload);
      if (payload.type) {
        trackEventOnFbPixel(
          ENTITY_PURCHSE_EVENT_NAME,
          fbPurchaseEventPayload
        );
      }
    }

    if (tiktokTrackingPixel) {
      trackEventOnTiktokPixel('CompletePayment', payload);
      if (payload.type) {
        trackEventOnFbPixel(ENTITY_PURCHSE_EVENT_NAME, payload);
      }
    }

    if (gaPixel) {
      const gaPurchseEventPayload = {
        ...payload,
        user_id: userId,
        items: formatCheckoutItemsForGA(payload?.checkoutItems)
      };
      trackEventOnGaPixel('purchase', gaPurchseEventPayload);
      if (payload.type) {
        trackEventOnGaPixel(
          ENTITY_PURCHSE_EVENT_NAME,
          gaPurchseEventPayload
        );
      }
    }

    if (twitterPixel) {
      trackPurchaseEventOnTwitterPixel('Purchase', payload);
      if (payload.type) {
        trackPurchaseEventOnTwitterPixel(
          ENTITY_PURCHSE_EVENT_NAME,
          payload
        );
      }
    }
  };

  const trackCustomPurchaseEventOnPixels = (event, payload) => {
    if (!event) return;
    // validate payload if it has value and currency
    if (
      !Object.prototype.hasOwnProperty.call(payload, 'value') ||
      !Object.prototype.hasOwnProperty.call(payload, 'currency')
    ) {
      console.warn(
        "Payload for custom event doesn't have value or currency"
      );
      return;
    }

    const userId = Auth.getUserIdFromSession();

    if (fbTrackingPixel) {
      const formattedCheckoutItems = formatCheckoutItemsForFbPixel(
        payload?.checkoutItems
      );
      const contentIds = formattedCheckoutItems.map((item) => item.id);
      const userDataPayload = getUserDataPayloadForFbPixel();

      trackEventOnFbPixel(event, {
        ...payload,
        ...userDataPayload,
        contents: formattedCheckoutItems,
        content_ids: contentIds,
        content_name: payload?.checkoutItems?.[0]?.name // can do this because there is always only one item in checkout (this is only for one time checkout).
      });
    }

    if (tiktokTrackingPixel) {
      trackEventOnTiktokPixel(event, payload);
    }

    if (gaPixel) {
      trackEventOnGaPixel(event, {
        ...payload,
        user_id: userId,
        items: formatCheckoutItemsForGA(payload?.checkoutItems)
      });
    }

    if (twitterPixel) {
      trackPurchaseEventOnTwitterPixel(event, payload);
    }
  };

  useEffect(() => {
    if (!firebaseInitialized) {
      return;
    }

    // Init Fb Tracking
    if (facebookPixel) {
      initFbTrackingPixel(facebookPixel, (pixel) => {
        if (postInitProcess?.trackPageView) {
          pixel.pageView();
          logPageViewEventToFirestore({
            pixelId: facebookPixel,
            platform: PIXEL_PLATFORMS.FACEBOOK
          });
        }
        if (postInitProcess?.trackInitCheckout) {
          pixel.track(INITIATE_CHECKOUT_EVENT_NAME);
          logPixelEventToFirestore({
            event: INITIATE_CHECKOUT_EVENT_NAME,
            pixelId: facebookPixel,
            platform: PIXEL_PLATFORMS.FACEBOOK
          });
        }
      });
    }

    // Init GA4 Tracking
    if (gaMeasurementId) {
      initGoogleAnalytics(gaMeasurementId, (pixel) => {
        if (postInitProcess?.trackPageView) {
          try {
            const pagePath = window.location.pathname;
            pixel.send({ hitType: 'pageview', page: pagePath });
            logPageViewEventToFirestore({
              pixelId: gaMeasurementId,
              platform: PIXEL_PLATFORMS.GA4
            });
          } catch (e) {
            console.warn(
              '>> Tracking pageview in initGoogleAnalytics callback failed.',
              e
            );
          }
        }
        if (postInitProcess?.trackInitCheckout) {
          // As requested by Summit Team. We can ask them to instead use being_checkout event which is recommended by GA
          [
            INITIATE_CHECKOUT_EVENT_NAME,
            'begin_checkout',
            'checkout_pageview'
          ].forEach((event) => {
            pixel.event(event);
            logPixelEventToFirestore({
              event,
              pixelId: gaMeasurementId,
              platform: PIXEL_PLATFORMS.GA4
            });
          });
        }
      });
    }

    // Init Tiktok Tracking
    if (tikTokPixel) {
      initTiktokTrackingPixel(tikTokPixel, (pixel) => {
        if (postInitProcess?.trackPageView) {
          pixel.pageView();
          logPageViewEventToFirestore({
            pixelId: tikTokPixel,
            platform: PIXEL_PLATFORMS.TIKTOK
          });
        }
        if (postInitProcess?.trackInitCheckout) {
          pixel.track(INITIATE_CHECKOUT_EVENT_NAME);
          logPixelEventToFirestore({
            event: INITIATE_CHECKOUT_EVENT_NAME,
            pixelId: tikTokPixel,
            platform: PIXEL_PLATFORMS.TIKTOK
          });
        }
      });
    }

    // Init X formarly Twitter Tracking
    if (twitterPixelId) {
      initTwitterPixel(twitterPixelId, (twq) => {
        if (postInitProcess?.trackPageView) {
          twq('track', 'PageView');
          logPageViewEventToFirestore({
            pixelId: twitterPixelId,
            platform: PIXEL_PLATFORMS.TWITTER
          });
        }
        if (postInitProcess?.trackInitCheckout) {
          twq('track', INITIATE_CHECKOUT_EVENT_NAME);
          logPixelEventToFirestore({
            event: INITIATE_CHECKOUT_EVENT_NAME,
            pixelId: twitterPixelId,
            platform: PIXEL_PLATFORMS.TWITTER
          });
        }
      });
    }

    return () => {
      clearFbTrackingPixel();
      clearGaPixel();
      clearTiktokTrackingPixel();
      clearTwitterPixel();
    };
    // Disabling the eslint rule below because we don't want to add more dependencies to this hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebaseInitialized, facebookPixel, tikTokPixel, gaMeasurementId]);

  return {
    trackEventOnFbPixel,
    trackSubscribeEventOnPixels,
    trackPurchaseEventOnPixels,
    trackCustomPurchaseEventOnPixels
  };
};

export default usePixelTracking;
